<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="cardHolderContent">
      <div class="cardHolderContainer">
        <div class="cardContainer">
          <div class="backContainer" @click="backBtn">
            <a-icon type="arrow-left" /> back
          </div>
          <div class="container">
            <div class="containerCenter">
              <div class="leftImg">
                <div class="leftImgPng">
                  <img
                  v-if="cardType === '0' && cardTypeId !== '60000004'"
                  src="@/assets/card1.png"
                  alt="card" />
                  <img v-if="cardType === '0' && cardTypeId === '60000004'"
                  src="@/assets/card_new3.png"
                  alt="card" />
                  <img v-if="cardType === '1'" src="@/assets/card1_new.svg" alt="" />
                  <div class="cardHolder">
                    {{ cardHolder }}
                  </div>
                </div>
              </div>
              <div class="rightText">
                <h4 class="balanceTextSty">
                  <div class="leftText">Balance: </div>
                  <div class="rightText">
                    <span class="balanceText">{{ balance }}</span>
                    <span>{{ currency || 'USD' }}</span>
                    <a-icon
                      class="seeBalanceIcon"
                      type="eye"
                      theme="twoTone"
                      v-if="status === 1"
                      @click="getBalance" />
                  </div>
                </h4>
                <h4>
                  <span>CARD NUMBER:</span>
                  <div>{{ card_number }}</div>
                </h4>
                <h4>
                  <span>EXPIRY DATE:</span>
                  <div>{{ expire_date }}</div>
                </h4>
                <h4>
                  <span>SECURITY CODE:</span>
                  <div>{{ card_cvv }}</div>
                </h4>
                <div class="rightTextButton">
                  <a-button
                    class="SearchBtn"
                    v-if="status === 9"
                    @click="underReview()"
                  >Under Review</a-button>
                  <a-button class="SearchBtn" v-if="status === 8 && needAttachmentsActive"
                    @click="showActivatedUploadModal()">Activate</a-button>
                  <a-button class="SearchBtn" v-else-if="status === 8 && !needAttachmentsActive"
                    @click="showActivatedModal()">Activate</a-button>
                  <a-button class="SearchBtn" v-if="status === 1" @click="seeDetailsBtn()">
                    <img class="eyeImg" src="@/assets/seeImg.png" alt="eye" />
                    View Details
                  </a-button>
                  <a-button class="SearchBtn rechargeBtn" v-if="status === 1 && cardType == 0"
                    @click="goCardRecharge()">
                    <img class="eyeImg" src="@/assets/downLoadImg.svg" alt="eye" />
                    Deposit</a-button>
                </div>
              </div>
            </div>
          </div>
          <div class="assetContainer" v-if="cardType == 1">
            <h3>My Asset</h3>
            <a-spin :spinning='assetDataLoading'>
              <div class="assetDataContainer">
                <a-space :size="60">
                  <div class="assetCard" v-for="item in assetData" :key="item.coin_type">
                    <div class="coinType">
                      <img v-if='item.coin_type === "GAS"' :src='GAS' alt="">
                      <img v-else-if='item.coin_type === "NEO"' :src="NEO" alt="">
                      <img v-else-if='item.coin_type === "USDT-TRC20"' :src="USDT" alt="">
                      <span class="coinName">{{ item.coin_type }}</span>
                    </div>
                    <div class="coinMsg">
                      <div class="coinTips">Address</div>
                      <div class="coinValue">
                        <span
                          class="transaction_hash"
                          v-clipboard:copy="item.address"
                          v-clipboard:success="onHashCopy"
                          v-clipboard:error="onHashError">
                          <span v-if="item.address">
                            {{ item.address.substring(0, 8) }}...{{
                              item.address.substring(item.address.length - 8, item.address.length)
                            }}
                          </span>
                          <span v-else>-</span>
                        </span>
                      </div>
                    </div>
                    <div class="coinMsg">
                      <div class="coinTips">Balance</div>
                      <div class="coinValue">{{ item.balance }}</div>
                    </div>
                    <div class="operation">
                      <div v-if="item.coin_type !== 'GAS' && item.coin_type !== 'NEO'"
                        class="operationBtn operationDepositBtn" @click="depositItem(item)">
                        Deposit
                      </div>
                      <div v-if="item.coin_type !== 'GAS' && item.coin_type !== 'NEO'"
                        class="operationBtn operationWithdrawBtn" @click="withdrawItem(item)">
                        Withdraw
                      </div>
                    </div>
                  </div>
                </a-space>
              </div>
            </a-spin>
          </div>
          <div class="historyContainer">
            <a-tabs @change="changeTab">
              <a-tab-pane key="1" tab="History">
                <a-table
                  :columns="columns"
                  :data-source="data"
                  rowKey="tx_id"
                  :loading="dataLoading"
                >
                </a-table>
              </a-tab-pane>
              <a-tab-pane key="2" tab="Deposit Txn" force-render>
                <a-table
                  :columns="depositColumns"
                  :data-source="depositData"
                  :loading="depositLoading"
                >
                  <span
                    slot="from_address"
                    slot-scope="text"
                    class="from_address"
                    v-clipboard:copy="text"
                    v-clipboard:success="onAddressCopy"
                    v-clipboard:error="onAddressError"
                  >
                    <span v-if="text">
                      {{ text.substring(0, 4) }}...{{
                        text.substring(text.length - 4, text.length)
                      }}
                    </span>
                    <span v-else>- </span>
                  </span>
                  <span
                    slot="transaction_hash"
                    slot-scope="text"
                    class="transaction_hash"
                    v-clipboard:copy="text"
                    v-clipboard:success="onHashCopy"
                    v-clipboard:error="onHashError"
                  >
                    <span v-if="text">
                      {{ text.substring(0, 4) }}...{{
                        text.substring(text.length - 4, text.length)
                      }}
                    </span>
                    <span v-else>-</span>
                  </span>
                  <span slot="status" slot-scope="text">
                    <span v-if="text === 0">Pending</span>
                    <span v-else-if="text === 1" class="success">Succeed</span>
                    <span v-else-if="text === 2" class="fail">Failed</span>
                    <span v-else-if="text === 3">Submitted</span>
                    <span v-else-if="text === 4">Pending</span>
                    <span v-else-if="text === 5" class="fail">Cancelled</span>
                    <span v-else-if="text === 6">Pending</span>
                  </span>
                  <span slot="create_time" slot-scope="text">
                    {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                  <span slot="update_time" slot-scope="text">
                    {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                </a-table>
              </a-tab-pane>
              <a-tab-pane key="deposit_from_wallet" tab="Transfer Txn" force-render>
                <a-table :columns="depositFromWalletColumns" :data-source="depositFromWalletData"
                  :loading="depositFromWalletLoading">
                  <span slot="create_time" slot-scope="text">
                    {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                  <span slot="update_time" slot-scope="text">
                    {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                  <span slot="tx_type" slot-scope="text">
            {{ text || '-' }}
          </span>
                  <span slot="status" slot-scope="text">
                    <span v-if="text === 0" class="g-pending">Pending</span>
                    <span v-else-if="text === 1" class="g-success">Succeed</span>
                    <span v-else-if="text === 2" class="g-process">Confirming</span>
                    <span v-else-if="text === 3" class="fail">Abnormal</span>
                    <span v-else-if="text === 4" class="fail">Failed</span>
                    <span v-else-if="text === 5" class="fail">Canceled</span>
                    <span v-else-if="text === 6" class="g-expire">Expire</span>
                    <span v-else-if="text === 7" class="g-pending">Refund in progress</span>
                    <span v-else-if="text === 8" class="g-success">Refund Succeed</span>
                    <span v-else-if="text === 9" class="fail">Refund failed</span>
                    <span v-else-if="text === 99" class="fail">Channel unmatched</span>
                  </span>
                  <span slot="note" slot-scope="text">
                    {{ text || '-' }}
                  </span>
                </a-table>
              </a-tab-pane>
              <a-tab-pane key="withdraw" tab="Withdraw" v-if="cardType == 1" force-render>
                <a-table
                  :columns="withdrawColumns"
                  :data-source="withdrawData"
                  :loading="withdrawLoading">
                  <span
                    slot="from_address"
                    slot-scope="text"
                    class="from_address"
                    v-clipboard:copy="text"
                    v-clipboard:success="onAddressCopy"
                    v-clipboard:error="onAddressError"
                  >
                    <span v-if="text">
                      {{ text.substring(0, 4) }}...{{
                        text.substring(text.length - 4, text.length)
                      }}
                    </span>
                    <span v-else>- </span>
                  </span>
                  <span
                    slot="transaction_hash"
                    slot-scope="text"
                    class="transaction_hash"
                    v-clipboard:copy="text"
                    v-clipboard:success="onHashCopy"
                    v-clipboard:error="onHashError">
                    <span v-if="text">
                      {{ text.substring(0, 4) }}...{{
                        text.substring(text.length - 4, text.length)
                      }}
                    </span>
                    <span v-else>-</span>
                  </span>
                  <span slot="status" slot-scope="text">
                    <span v-if="text === 0">Pending</span>
                    <span v-else-if="text === 1" class="success">Succeed</span>
                    <span v-else-if="text === 2" class="fail">Failed</span>
                    <span v-else-if="text === 3">Submitted</span>
                    <span v-else-if="text === 4">Pending</span>
                    <span v-else-if="text === 5" class="fail">Cancelled</span>
                    <span v-else-if="text === 6">Pending</span>
                  </span>
                  <span slot="create_time" slot-scope="text">
                    {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                  <span slot="update_time" slot-scope="text">
                    {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                </a-table>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
    <a-modal
      v-model="sendMailVisible"
      title="View Details"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Verify Google Authenticator">
          <a-row :gutter="8">
            <a-col :span="24">
              <a-input v-decorator="[
                'google-code',
                {
                  rules: [
                    {
                      required: true,
                      len: 6,
                      message:
                      'Please enter the six-digit code from Google Authenticator',
                    },
                  ],
                },
              ]"  placeholder="Google Authenticator Code"/>
            </a-col>
            <!-- <a-col :span="6">
              <a-button
                class="sendMailBtn"
                type="primary"
                @click="getCaptcha"
                :loading="sendEmailLoading"
                :disabled="sendEmailDisabled"
              >{{ sendEmailText }}</a-button>
            </a-col> -->
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model="sendActiveMailVisible"
      title="Active Card"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="formActive" @submit="handleSubmitActive">
        <a-form-item label="Email Verification Code">
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input v-decorator="[
                'email-code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the captcha you got!',
                    },
                  ],
                },
              ]" />
            </a-col>
            <a-col :span="6">
              <a-button
                class="sendMailBtn"
                type="primary"
                @click="getCaptcha"
                :disabled="sendEmailDisabled"
              >
                {{sendEmailText }}</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model="depositVisible"
      title="Deposit"
      :footer="null"
      :maskClosable="false"
      :destroyOnClose="true"
      width="800px"
      centered
    >
      <div class="modalContainer">
        <div class="containerLeft">
          <qrcode-vue :value="depositAddress" size="140"></qrcode-vue>
        </div>
        <div class="containerRight">
          <div class="address">
            <div class="addressDeposit">
              Asset Name:
            </div>
            <div class="addressText">
              {{ chooseItem.coin_type }}
            </div>
          </div>
          <div class="address">
            <div class="addressDeposit">
              Deposit Address:
            </div>
            <div class="addressText">
              <span class="text">{{ depositAddress }}</span>
              <span
                class="copyABtn"
                v-clipboard:copy="depositAddress"
                v-clipboard:success="onAddressCopy"
                v-clipboard:error="onAddressError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modalHashInputContainer">
        <div class="inputContent">
          <div class="inputLabel">Transaction Hash</div>
          <a-input class="stepInput" :value="tx_hash" placeholder="Please enter transaction hash"
            @change="hashChange" />
        </div>
      </div>
      <div class="modalDepositCreditCardTips">Note:</div>
      <div class="modalDepositCreditCardTipsContainer">
        About card activation:
        <br />
        * Please ensure the total value in your crypto account is higher than 100 USDT.
        <br />
        * When the balance in the crypto account is less than 100 USDT,
        you cannot use the card to pay for purchases, but
        you can still withdraw the cryptos.
        <br />
        * Once the transfer is confirmed, please enter the corresponding transaction hash.
      </div>
      <div class="modalDepositGoToBtnContainer">
        <a-button class="modalDepositGoToBtn" :disabled='submitDepositDisabled' type='primary'
          @click="submitDepositHash">
          Confirm </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="withdrawVisible"
      title="Withdraw"
      :footer="null"
      :maskClosable="false"
      :destroyOnClose="true"
      @cancel="closeWithdrawModal"
      width="800px"
      centered
    >
      <div class="withdrawModalContainer">
        <div class="inputContent">
          <div class="inputLabel">Address</div>
          <a-input
          class="stepInput"
          :value="withdrawAddress"
          placeholder="Please enter your  withdraw address"
            @change="(e) => (this.withdrawAddress = e.target.value)" />
        </div>
        <div class="inputContent">
          <div class="inputLabel">Amount</div>
          <a-input
            class="stepInput stepInputAddon"
            :value="withdrawAmount"
            placeholder="Please enter withdraw amount"
            @change="withdrawAmountChange">
            <div slot="suffix" class="suffixText">{{ chooseItem.coin_type }} <span>|</span> <a
                @click="chooseAllAmount">All</a></div>
          </a-input>
          <div class="availableContainer">
            <div>Available</div>
            <div>{{ chooseItem.balance }} {{ chooseItem.coin_type }}</div>
          </div>
        </div>
        <div class="inputContent">
          <div class="inputLabel">Email Verification Code:</div>
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input
                class="stepInput"
                :value="emailCode"
                placeholder="Please enter the captcha you got"
                @change="(e) => (this.emailCode = e.target.value)"
              />
            </a-col>
            <a-col :span="6">
              <a-button
                class="withdrawSendMailBtn"
                type="primary"
                @click="getCaptcha"
                :loading="sendEmailLoading"
                :disabled="sendEmailDisabled"
              >{{ sendEmailText }}</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="modalDepositCreditCardTips">Note:</div>
      <div class="modalDepositCreditCardTipsContainer">
        About withdraw activation:
        <br />
        * Please double check the address you transfer to.
        <br />
        * You need to pay a network fee for the withdrawal.
        <br />
        * It takes 1-3 days to complete the withdrawal.
        Your card will be frozen in the meantime, and cannot be used for
        purchases.
      </div>
      <div class="modalDepositGoToBtnContainer">
        <a-button
          class="modalDepositGoToBtn"
          :disabled='!withdrawAddress || !withdrawAmount'
          type='primary'
          @click="submitWithdraw"> Confirm </a-button>
      </div>
    </a-modal>


    <a-modal
      v-model="sendActiveUploadVisible"
      title="Active bank account"
      :footer="null"
      :maskClosable="false"
      centered>
      <div style="margin-bottom: 20px">
        Please upload a selfie with passport
         and bank card(front side), to activate your bank account.
      </div>
      <a-form :form="formUploadActive" @submit="handleSubmitActiveWithAttach">
        <a-form-item label="">
          <a-input style="display: none;" v-decorator="[
            'img',
            {
              rules: [
                {
                  required: true,
                  message: 'Please upload the file!',
                },
              ],
            },
          ]" />
          <UploadSel v-model="uploadImg" :http="false" />
        </a-form-item>
        <a-form-item label="Email Verification Code">
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input v-decorator="[
                'email-code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter the captcha you got!',
                    },
                  ],
                },
              ]" />
            </a-col>
            <a-col :span="6">
              <a-button
                class="sendMailBtn"
                type="primary"
                @click="getCaptcha"
                :disabled="sendEmailDisabled">{{ sendEmailText }}</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Active </a-button>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import BigNumber from 'bignumber.js';
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
// import Back from '@/components/Back.vue';
import NewFooter from '@/components/NewFooter.vue';
import { regNumber } from '@/utils/reg';
import {
  getAccountKycCard,
  sendEmail,
  getHistory,
  getCVV,
  getCardNo,
  activeCard,
  getUserAccount,
  getDepositHistory,
  getDepositFromWalletHistory,
  getCardAsset,
  submitTxHash,
  submitCardWithdraw,
  getGaStatus,
} from '@/utils/api';
import NEO from '@/assets/NEO.png';
import GAS from '@/assets/gasImg.svg';
import USDT from '@/assets/USDT.png';
import { message } from 'ant-design-vue';
import UploadSel from './components/UploadSel/index.vue';

const usdColumns = [
  {
    title: 'Date',
    dataIndex: 'posting_date',
    key: 'posting_date',
  },
  { title: 'Transaction ID', dataIndex: 'tx_id', key: 'tx_id' },
  { title: 'Type', dataIndex: 'type', key: 'type' },

  { title: 'Txn Currency', dataIndex: 'tx_currency', key: 'tx_currency' },
  { title: 'Txn Amount', dataIndex: 'tx_amount', key: 'tx_amount' },
  { title: 'Billing Amount', dataIndex: 'debit_usd', key: 'debit_usd' },
];
const eurColumns = [
  {
    title: 'Date',
    dataIndex: 'posting_date',
    key: 'posting_date',
  },
  { title: 'Transaction ID', dataIndex: 'tx_id', key: 'tx_id' },
  { title: 'Description', dataIndex: 'description', key: 'description' },
  { title: 'Currency', dataIndex: 'tx_currency', key: 'tx_currency' },
  { title: 'Amount', dataIndex: 'tx_amount', key: 'tx_amount' },
  { title: 'Amount In EUR', dataIndex: 'debit', key: 'debit' },
];

const depositFromWalletColumns = [
  {
    title: 'Transaction ID',
    dataIndex: 'external_tx_id',
    key: 'external_tx_id',
    scopedSlots: { customRender: 'external_tx_id' },
  },
  {
    title: 'Type',
    dataIndex: 'tx_type',
    key: 'tx_type',
    scopedSlots: { customRender: 'tx_type' },
  },
  { title: 'Currency', dataIndex: 'currency', key: 'currency' },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Time Created',
    dataIndex: 'create_time',
    key: 'create_time',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    scopedSlots: { customRender: 'note' },
  },
];
export default {
  name: 'Cardholder',
  components: {
    QrcodeVue,
    NavHeader,
    NavHeaderMobile,
    NewFooter,
    // Back,
    UploadSel,
  },
  watch: {
    uploadImg(val) {
      this.formUploadActive.setFieldsValue({ img: val });
    },
  },
  data() {
    return {
      NEO,
      GAS,
      USDT,
      assetData: [],
      columns: usdColumns,
      data: [],
      depositColumns: [],
      depositFromWalletColumns,
      depositFromWalletData: [],
      depositData: [],
      withdrawColumns: [
        { title: 'Transaction ID', dataIndex: 'tx_id', key: 'tx_id' },
        {
          title: 'From Address',
          dataIndex: 'from_address',
          key: 'from_address',
          scopedSlots: { customRender: 'from_address' },
        },
        {
          title: 'Asset',
          dataIndex: 'coin_type',
          key: 'coin_type',
        },
        {
          title: 'Amount',
          dataIndex: 'tx_amount',
          key: 'tx_amount',
        },
        {
          title: 'Transaction Hash',
          dataIndex: 'transaction_hash',
          key: 'transaction_hash',
          scopedSlots: { customRender: 'transaction_hash' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Time Created',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: { customRender: 'create_time' },
        },
        {
          title: 'Time Updated',
          dataIndex: 'update_time',
          key: 'update_time',
          scopedSlots: { customRender: 'update_time' },
        },
      ],
      withdrawData: [],
      depositAddress: '',
      cardType: '',
      sendEmailText: 'Send',
      assetDataLoading: true,
      dataLoading: false,
      depositLoading: false,
      depositFromWalletLoading: false,
      withdrawLoading: false,
      timer: null,
      defaultTime: 60,
      sendEmailLoading: false,
      sendEmailDisabled: false,
      balance: '*',
      form: this.$form.createForm(this, { name: 'seeCVV' }),
      formActive: this.$form.createForm(this, { name: 'activeCard' }),
      formUploadActive: this.$form.createForm(this, { name: 'formUploadActive' }),
      former_month_year: null,
      latter_month_year: null,
      tx_id: null,
      mail_code_token: '',
      showAddressDetails: false,
      sendMailVisible: false,
      sendActiveMailVisible: false,
      sendActiveUploadVisible: false,
      depositVisible: false,
      withdrawVisible: false,
      withdrawAddress: '',
      withdrawAmount: null,
      postal_address: {},
      cardEnding: '',
      mail: '',
      card_number: '**** **** **** ****',
      expire_date: '** / ****',
      card_cvv: '***',
      card_type_id: this.$route.query.id,
      card_no: '',
      status: 0,
      cardHolder: '',
      chooseItem: {},
      tx_hash: '',
      submitDepositDisabled: true,
      submitWithdrawDisabled: true,
      emailCode: '',
      cardTypeId: '',
      currency: '',
      needAttachmentsActive: false, // 30000001
      uploadImg: '',
    };
  },
  created() {
    this.mail = localStorage.getItem('inst_mail');
    const params = {
      cardTypeId: this.$route.query.cardTypeId,
    };
    this.cardType = this.$route.query.cardType;
    this.cardTypeId = this.$route.query.cardTypeId;
    this.currency = this.$route.query.currency;
    this.initDepositColumns();
    getAccountKycCard(params).then((res) => {
      this.card_no = res.result[0].card_no;
      this.status = res.result[0].status;
      this.cardHolder = `${res.result[0].first_name} ${res.result[0].last_name}`;
      if (res.result[0].if_attachments) {
        this.needAttachmentsActive = true;
      } else {
        this.needAttachmentsActive = false;
      }
      if (res.result[0].status === 1) {
        this.searchHistory();
        this.searchDepositFromWallet();
      }
    });
  },
  methods: {
    onAddressCopy() {
      this.$message.success('Copy Address Success');
    },
    onAddressError() {
      this.$message.error('Copy Address Failed');
    },
    onHashCopy() {
      this.$message.success('Copy Hash Success');
    },
    onHashError() {
      this.$message.error('Copy Hash Failed');
    },
    initDepositColumns() {
      if (this.$route.query.currency === 'EUR') {
        this.columns = eurColumns;
      }
      if (this.$route.query.currency === 'USD') {
        this.columns = usdColumns;
      }
      if (this.$route.query.cardType === '0') {
        this.depositColumns = [
          { title: 'Transaction ID', dataIndex: 'tx_id', key: 'tx_id' },
          {
            title: 'From Address',
            dataIndex: 'from_address',
            key: 'from_address',
            scopedSlots: { customRender: 'from_address' },
          },
          {
            title: 'Asset',
            dataIndex: 'coin_type',
            key: 'coin_type',
          },
          {
            title: 'Amount',
            dataIndex: 'tx_amount',
            key: 'tx_amount',
          },
          {
            title: 'Transaction Hash',
            dataIndex: 'transaction_hash',
            key: 'transaction_hash',
            scopedSlots: { customRender: 'transaction_hash' },
          },
          {
            title: 'Currency',
            dataIndex: 'currency_type',
            key: 'currency_type',
          },
          {
            title: 'Credit Amount',
            dataIndex: 'currency_amount',
            key: 'currency_amount',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            scopedSlots: { customRender: 'status' },
          },
          {
            title: 'Time Created',
            dataIndex: 'create_time',
            key: 'create_time',
            scopedSlots: { customRender: 'create_time' },
          },
          {
            title: 'Time Updated',
            dataIndex: 'update_time',
            key: 'update_time',
            scopedSlots: { customRender: 'update_time' },
          },
        ];
      } else {
        this.depositColumns = [
          { title: 'Transaction ID', dataIndex: 'tx_id', key: 'tx_id' },
          {
            title: 'From Address',
            dataIndex: 'from_address',
            key: 'from_address',
            scopedSlots: { customRender: 'from_address' },
          },
          {
            title: 'Asset',
            dataIndex: 'coin_type',
            key: 'coin_type',
          },
          {
            title: 'Amount',
            dataIndex: 'tx_amount',
            key: 'tx_amount',
          },
          {
            title: 'Transaction Hash',
            dataIndex: 'transaction_hash',
            key: 'transaction_hash',
            scopedSlots: { customRender: 'transaction_hash' },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            scopedSlots: { customRender: 'status' },
          },
          {
            title: 'Time Created',
            dataIndex: 'create_time',
            key: 'create_time',
            scopedSlots: { customRender: 'create_time' },
          },
          {
            title: 'Time Updated',
            dataIndex: 'update_time',
            key: 'update_time',
            scopedSlots: { customRender: 'update_time' },
          },
        ];
        this.columns.push(
          {
            title: 'Repayment',
            dataIndex: 'coin_type_amount',
            key: 'coin_type_amount',
          },
          {
            title: 'Asset Price',
            dataIndex: 'usd_price',
            key: 'usd_price',
          },
          {
            title: 'Asset Balance',
            dataIndex: 'balance',
            key: 'balance',
          },
        );
      }
      this.columns.push({ title: 'Description', dataIndex: 'description', key: 'description' });
    },
    withdrawAmountChange(e) {
      const validAmount = !(this.chooseItem.coin_type === 'NEO' && !regNumber.test(e.target.value));
      if (validAmount) {
        this.withdrawAmount = e.target.value;
      }
    },
    closeWithdrawModal() {
      this.withdrawAmount = null;
    },
    seeDetailsBtn() {
      const mail = localStorage.getItem('inst_mail');
      getGaStatus(mail).then((res) => {
        if (res.code === 0) {
          if (res.result.ga_enable && res.result.GABound) {
            this.sendMailVisible = true;
          } else {
            this.getHideMessageInfo('');
          }
        }
      });
    },
    getCaptcha() {
      this.sendEmailLoading = true;
      this.sendEmailDisabled = true;
      const params = {
        mail: this.mail,
      };
      sendEmail(params)
        .then((res) => {
          this.mail_code_token = res.result;
          this.sendEmailLoading = false;
          this.$message.success('send email success');
          this.timer = setInterval(this.CountDown, 1000);
        })
        .catch(
          () => {
            this.sendEmailLoading = false;
            this.sendEmailDisabled = false;
          },
        );
    },
    CountDown() {
      if (this.defaultTime > 0) {
        this.defaultTime = this.defaultTime - 1;
        this.sendEmailText = this.defaultTime;
      } else {
        clearInterval(this.timer);
        this.defaultTime = 60;
        this.sendEmailDisabled = false;
        this.sendEmailText = 'Send';
      }
    },
    getBalance() {
      if (this.balance === '*') {
        getUserAccount(this.card_no).then((res) => {
          this.balance = res.result.available_balance;
        });
      } else {
        this.balance = '*';
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.getHideMessageInfo(values['google-code']);
        }
      });
    },
    getHideMessageInfo(ga) {
      if (this.$route.query.virtualCard === '0') {
        this.getCvvInfo(ga);
      } else if (this.$route.query.virtualCard === '1') {
        this.getCardNoInfo(ga);
      }
    },
    getCvvInfo(ga) {
      getCVV(this.card_no, ga).then(
        (res) => {
          if (res.code === 0) {
            this.$message.success('success');
            this.sendMailVisible = false;
            this.card_number = res.result.card_number
              .replace(/[\s]/g, '')
              .replace(/(\d{4})(?=\d)/g, '$1 ');
            this.expire_date = res.result.expire;
            this.card_cvv = res.result.cvv;
          }
        },
      );
    },
    getCardNoInfo(ga) {
      getCardNo(this.card_no, ga).then(
        (res) => {
          if (res.code === 0) {
            this.$message.success('success');
            this.sendMailVisible = false;
            this.card_number = res.result
              .replace(/[\s]/g, '')
              .replace(/(\d{4})(?=\d)/g, '$1 ');
          }
        },
      );
    },
    showActivatedModal() {
      this.sendActiveMailVisible = true;
    },
    showActivatedUploadModal() {
      this.sendActiveUploadVisible = true;
    },
    underReview() {
      message.info('Under Review');
    },
    handleSubmitActive(e) {
      e.preventDefault();
      this.formActive.validateFields((err, values) => {
        if (!err) {
          const params = {
            card_no: this.card_no,
            token: this.mail_code_token,
            email_token: values['email-code'],
          };
          activeCard(params).then((res) => {
            if (res.code === 0) {
              this.$message.success('Active Card Success');
              this.sendActiveMailVisible = false;
              this.status = 1;
              const kycCardParams = {
                cardTypeId: this.$route.query.cardTypeId,
              };
              this.cardType = this.$route.query.cardType;
              getAccountKycCard(kycCardParams).then((kycCardRes) => {
                this.card_no = kycCardRes.result[0].card_no;
                if (kycCardRes.result[0].status === 1) {
                  this.searchHistory();
                }
              });
            }
          });
        }
      });
    },
    handleSubmitActiveWithAttach(e) {
      e.preventDefault();
      console.log(this.card_no, this.uploadImg);
      this.formUploadActive.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          const activeCardParams = {
            card_no: this.card_no,
            token: this.mail_code_token,
            email_token: values['email-code'],
            activeDoc: this.uploadImg,
          };
          activeCard(activeCardParams).then((res) => {
            if (res.code === 0) {
              this.$message.success('Active Card Under Review');
              this.sendActiveMailVisible = false;
              this.status = 9;
              const params = {
                cardTypeId: this.$route.query.cardTypeId,
              };
              this.cardType = this.$route.query.cardType;
              this.sendActiveUploadVisible = false;
              getAccountKycCard(params).then((res2) => {
                this.card_no = res2.result[0].card_no;
                // if (res2.result[0].status === 9) {
                //   this.searchHistory();
                // }
              });
            }
          });
        }
      });
    },
    depositItem(item) {
      if (item.coin_type === 'GAS' || item.coin_type === 'NEO') {
        this.$message.error('This coin type no longer supports deposit');
        return;
      }
      this.chooseItem = item;
      this.depositAddress = item.address;
      this.depositVisible = true;
    },
    withdrawItem(item) {
      if (item.coin_type === 'GAS' || item.coin_type === 'NEO') {
        this.$message.error('This coin type no longer supports withdraw');
        return;
      }
      this.chooseItem = item;
      this.withdrawVisible = true;
    },
    onChange(date) {
      if (date) {
        this.former_month_year = date[0].valueOf();
        this.latter_month_year = date[1].valueOf();
      }
    },
    searchHistory() {
      this.assetDataLoading = true;
      this.dataLoading = true;
      this.depositLoading = true;

      this.withdrawLoading = true;
      this.searchDepositFromWallet();
      const params = {
        card_no: this.card_no,
      };
      getHistory(params)
        .then((res) => {
          this.dataLoading = false;
          if (res.code === 0) {
            const response = res.result.map((item) => {
              const newItem = item;
              if (item.type) {
                const typeEnum = {
                  1: 'Auth Success',
                  2: 'Recharge',
                  3: 'Withdraw',
                  4: 'Transfer (In)',
                  5: 'Transfer (Out)',
                  6: 'Other',
                  7: 'Adjustment',
                  8: 'Refund',
                  9: 'Failed',
                  10: 'Verification',
                  11: 'Void',
                };
                newItem.type = typeEnum[item.type] || '';
              }
              if (item.card_repayment) {
                newItem.coin_type_amount = `${item.card_repayment.coin_tx_amount} ${item.card_repayment.coin_type}`
                  || '-';
                newItem.usd_price = BigNumber(Math.abs(item.debit_usd))
                  .dividedBy(BigNumber(item.card_repayment.coin_tx_amount))
                  .toFixed(4) || '-';
                newItem.balance = item.card_repayment.balance;
              } else {
                newItem.coin_type_amount = '-';
                newItem.usd_price = '-';
                newItem.balance = '-';
              }
              return newItem;
            });
            this.data = response;
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
      const depositParams = {
        // former_month_year: this.former_month_year,
        // latter_month_year: this.latter_month_year,
        card_type_id: this.$route.query.cardTypeId,
        tx_type: ['70', '90'],
      };
      getDepositHistory(depositParams)
        .then((res) => {
          this.depositLoading = false;
          if (res.code === 0) {
            this.depositData = res.result.records;
          }
        })
        .catch(() => {
          this.depositLoading = false;
        });
      // 获取asset列表
      if (this.$route.query.cardType === 1 || this.$route.query.cardType === '1') {
        this.assetDataLoading = true;
        getCardAsset()
          .then((res) => {
            this.assetDataLoading = false;
            if (res.code === 0) {
              this.assetData = res.result;
            }
          })
          .catch(() => {
            this.assetDataLoading = false;
          });
      }
      // 获取withdraw列表
      const withdrawParams = {
        card_type_id: this.$route.query.cardTypeId,
        tx_type: ['71'],
      };
      getDepositHistory(withdrawParams)
        .then((res) => {
          this.withdrawLoading = false;
          if (res.code === 0) {
            this.withdrawData = res.result.records;
          }
        })
        .catch(() => {
          this.withdrawLoading = false;
        });
    },
    searchDepositFromWallet() {
      this.depositFromWalletLoading = true;
      const depositParams = {
        // former_month_year: this.former_month_year,
        // latter_month_year: this.latter_month_year,
        to_address: this.card_no,
        card_type_id: this.$route.query.cardTypeId,
      };
      getDepositFromWalletHistory(depositParams)
        .then((res) => {
          this.depositFromWalletLoading = false;
          if (res.code === 0) {
            this.depositFromWalletData = res.result.records.map((item) => {
              const txTypeEnum = {
                '01': 'Card Deposit',
                '02': 'Bank Deposit',
                '03': 'Transfer Out',
                '04': 'Transfer In',
                '05': 'Buy Crypto',
                '06': 'Refund In',
                '07': 'Refund Out',
                '08': 'Deduction In',
                '09': 'Deduction Out',
                10: 'Burn',
                11: 'Pay Out',
                12: 'Wallet FX',
                13: 'Manual Deposit',
                14: 'SEPA Transfer out',
                15: 'Platform Deposit',
                16: 'Send to card',
              };

              return {
                ...item,
                tx_type: txTypeEnum[item.tx_type] || item.tx_type,
              };
            });
          }
        })
        .catch(() => {
          this.depositFromWalletLoading = false;
        });
    },
    changeTab(e) {
      const params = {
        card_no: this.card_no,
      };
      if (this.status === 1) {
        if (e === '1') {
          this.dataLoading = true;
          getHistory(params)
            .then((res) => {
              this.dataLoading = false;
              if (res.code === 0) {
                const response = res.result.map((item) => {
                  const newItem = item;
                  if (item.card_repayment) {
                    newItem.coin_type_amount = `${item.card_repayment.coin_tx_amount} ${item.card_repayment.coin_type}`
                      || '-';
                    newItem.usd_price = BigNumber(Math.abs(item.debit_usd))
                      .dividedBy(
                        BigNumber(item.card_repayment.coin_tx_amount),
                      )
                      .toFixed(4) || '-';
                    newItem.balance = item.card_repayment.balance;
                  } else {
                    newItem.coin_type_amount = '-';
                    newItem.usd_price = '-';
                    newItem.balance = '-';
                  }
                  return newItem;
                });
                this.data = response;
              }
            })
            .catch(() => {
              this.dataLoading = false;
            });
        } else if (e === '2') {
          this.depositLoading = true;
          const depositParams = {
            card_type_id: this.$route.query.cardTypeId,
            tx_type: ['70', '90'],
          };
          getDepositHistory(depositParams)
            .then((res) => {
              this.depositLoading = false;
              if (res.code === 0) {
                this.depositData = res.result.records;
              }
            })
            .catch(() => {
              this.depositLoading = false;
            });
        } else if (e === 'withdraw') {
          this.withdrawLoading = true;
          const withdrawParams = {
            card_type_id: this.$route.query.cardTypeId,
            tx_type: ['71'],
          };
          getDepositHistory(withdrawParams)
            .then((res) => {
              this.withdrawLoading = false;
              if (res.code === 0) {
                this.withdrawData = res.result.records;
              }
            })
            .catch(() => {
              this.withdrawLoading = false;
            });
        } else if (e === 'deposit_from_wallet') {
          this.searchDepositFromWallet();
        }
      }
    },
    goCardRecharge() {
      this.$router.push({
        path: '/cardRecharge',
        query: {
          id: this.card_no,
          cardTypeId: this.$route.query.cardTypeId,
          depositTransactionFee: this.$route.query.depositTransactionFee,
        },
      });
    },
    hashChange(e) {
      this.tx_hash = e.target.value;
      if (e.target.value.length === 64 || e.target.value.length === 66) {
        this.submitDepositDisabled = false;
      } else {
        this.submitDepositDisabled = true;
      }
    },
    submitDepositHash() {
      const params = {
        tx_hash: this.tx_hash,
        card_type_id: this.$route.query.cardTypeId,
        coin_type: this.chooseItem.coin_type,
      };
      submitTxHash(params).then((res) => {
        if (res.code === 0) {
          this.depositVisible = false;
          this.$message.success(`${res.msg}`);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    chooseAllAmount(e) {
      e.preventDefault();
      if (this.chooseItem.coin_type === 'NEO') {
        this.withdrawAmount = Math.floor(this.chooseItem.balance);
      } else {
        this.withdrawAmount = this.chooseItem.balance;
      }
    },
    submitWithdraw() {
      const params = {
        address: this.withdrawAddress,
        coin_type: this.chooseItem.coin_type,
        tx_amount: this.withdrawAmount,
        token: this.mail_code_token,
        email_token: this.emailCode,
      };
      if (this.withdrawAmount > this.chooseItem.balance) {
        this.$message.error('Insufficient available quota');
      } else {
        submitCardWithdraw(params).then((res) => {
          this.withdrawVisible = false;
          this.withdrawAddress = '';
          this.withdrawAmount = '';
          this.emailCode = '';
          if (res.code === 0) {
            this.$message.success('withdraw success');
            this.assetDataLoading = true;
            getCardAsset()
              .then((cardRes) => {
                this.assetDataLoading = false;
                if (cardRes.code === 0) {
                  this.assetData = cardRes.result;
                }
              })
              .catch(() => {
                this.assetDataLoading = false;
              });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    backBtn() {
      this.$router.push({
        path: '/my',
        query: {
          tab: 'myCard',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardHolderContent {
  width: 100%;
  background: linear-gradient(104.49deg, #fff4ef 0%, #edf6ff 99.7%);
}

.cardHolderContainer {
  max-width: 1280px;
  padding: 80px 0;
  margin: auto;
  min-height: calc(100vh - 120px);
  position: relative;

  @media (max-width: 750px) {
    width: 100%;
    padding-bottom: 0;
  }

  .cardContainer {
    width: 100%;
    padding-top: 24px;

    .container {
      max-width: 1280px;
      border-radius: 4px;
      background: #fff;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 10px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);

      @media (max-width: 750px) {
        margin-top: 10px;
      }

      .containerTop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 48px;

        @media (max-width: 750px) {
          padding: 24px;
        }

        .inUseSty {
          padding: 8px;
          background: #b4eeb4;
          border-radius: 4px;
          font-weight: bold;
        }
      }

      .leftImgText {
        padding: 12px 48px;
      }

      .containerCenter {
        display: flex;
        flex: 1;
        padding: 0 100px;

        @media (max-width: 750px) {
          padding: 0;
        }

        .leftImg {
          flex: 1;
          padding: 48px 0;
          margin-right: 60px;
          align-items: flex-end;
          display: flex;

          h3 {
            padding: 12px 48px;
          }

          .leftImgPng {
            width: 360px;
            position: relative;

            img {
              width: 100%;
              border-radius: 10px;
            }

            .cardHolder {
              position: absolute;
              bottom: 16px;
              left: 26px;
              color: #fff;
              font-weight: bold;
              font-size: 16px;
            }
          }

          @media (max-width: 750px) {
            width: 100%;

            .leftImgPng {
              width: 100%;
            }
          }
        }

        .rightText {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;

          .rightTextButton {
            @media (max-width: 750px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }

          h4 {
            margin: 10px 0;
            width: 400px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 750px) {
              width: 100%;
            }

            span {
              opacity: 0.4;
            }
          }

          .balanceTextSty {
            margin: 0;

            .leftText {
              font-family: "Poppins-Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #000000;
              mix-blend-mode: normal;
              display: flex;
              align-items: center;
            }

            .rightText {
              color: #0045ff;
              font-size: 20px;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;

              .balanceText {
                font-family: "DIN Pro";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
                margin-right: 5px;
              }

              span {
                font-size: 14px;
                opacity: 1;
              }
            }

            .seeBalanceIcon {
              cursor: pointer;
              margin-left: 8px;
            }
          }

          .SearchBtn {
            background: #0045ff;
            border-radius: 8px;
            height: 48px;
            width: 180px;
            color: #fff;
            margin-top: 20px;

            .eyeImg {
              height: 20px;
              margin-right: 6px;
              margin-top: -5px;
            }
          }

          .rechargeBtn {
            margin-left: 40px;

            @media (max-width: 750px) {
              margin-left: 0;
            }
          }
        }

        .rightTextBtn {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0 48px;

          .btnSty {
            width: 200px;
            background: rgba(64, 71, 214, 1);
            border-radius: 24px;
            height: 48px;
            opacity: 0.8;
          }
        }
      }

      @media (max-width: 750px) {
        .containerCenter {
          flex-direction: column;

          .rightText {
            padding: 0 24px;
          }

          .leftImg {
            padding: 24px;

            h3 {
              padding: 12px 0;
            }
          }

          .rightTextBtn {
            margin-bottom: 24px;
          }

          .leftImgText {
            padding: 12px 0;
          }
        }
      }
    }

    .assetContainer {
      max-width: 1280px;
      border-radius: 4px;
      background: #fff;
      margin-bottom: 10px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      padding: 40px 100px;

      @media (max-width: 750px) {
        margin-top: 10px;
        padding: 40px;
      }

      h3 {
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 20px;
      }

      .assetDataContainer {
        width: 100%;
        height: 220px;
        display: flex;

        @media (max-width: 750px) {
          height: auto;
        }

        .ant-space {
          @media (max-width: 750px) {
            display: flex;
            flex-direction: column;

            .ant-space-item {
              margin-right: 0 !important;
              margin-bottom: 24px;
            }
          }
        }

        .assetCard {
          width: 320px;
          height: 218px;
          border-radius: 8px;
          padding: 20px;
          background: linear-gradient(113.75deg, #eaf0ff 1.3%, #f8faff 99.74%);

          .coinType {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
              width: 24px;
              margin-right: 20px;
            }

            .coinName {
              height: 24px;
              line-height: 24px;
              font-family: "Poppins-Regular";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
            }
          }

          .coinMsg {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .coinTips {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: rgba(0, 0, 0, 0.4);
            }

            .coinValue {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }
          }

          .operation {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;

            .operationBtn {
              width: 130px;
              height: 36px;
              border-radius: 4px;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            .operationDepositBtn {
              background: #000;
              color: #fff;

              &:hover {
                box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
              }
            }

            .operationWithdrawBtn {
              color: #000;
              border: 1px solid #000;

              &:hover {
                box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
              }
            }
          }
        }
      }
    }

    .historyContainer {
      background: #fff;

      h2 {
        margin-left: 16px;
      }

      .ant-tabs {
        padding: 40px 100px;

        @media (max-width: 750px) {
          padding: 0;
          margin: 24px 0;
        }
      }
    }
  }
}

.sendMailBtn {
  width: 100%;
}

.confirmContainer {
  text-align: center;

  .goToBtn {
    background: #0045ff;
    border-radius: 8px;
    height: 40px;
    width: 100px;
    color: #fff;
  }
}

.searchContent {
  margin: 16px;

  .txIdInput {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
  }

  .searchHistory {
    background: #0045ff;
    color: #fff;
    margin-left: 20px;
  }

  @media (max-width: 750px) {
    display: flex;

    /deep/ .ant-calendar-picker {
      flex: 3;
    }

    .searchHistory {
      flex: 1;
    }
  }
}

/deep/ .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
  // display: -webkit-box;
  // -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.from_address {
  cursor: pointer;
  color: #0045ff;
}

.transaction_hash {
  cursor: pointer;
  color: #0045ff;
}

.success {
  color: #52c41a;
}

.fail {
  color: red;
}

.modalContainer {
  display: flex;
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  .containerLeft {
    width: 180px;
    height: 180px;
    padding: 20px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    margin-right: 40px;

    @media (max-width: 750px) {
      margin-right: 0;
    }
  }

  .containerRight {
    @media (max-width: 750px) {
      width: 100%;
      margin-top: 40px;
    }

    .address {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 12px;
      color: #000;

      @media (max-width: 750px) {
        margin-bottom: 0;
      }

      .addressDeposit {
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.4;
        margin-bottom: 18px;
      }

      .addressText {
        .text {
          font-family: "Poppins-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #000000;
        }

        .copyABtn {
          margin-left: 4px;
        }
      }
    }

    .ant-row {
      .ant-form-item-required {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.modalHashInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;

  .inputContent {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: 960px) {
      width: 100%;
    }

    .inputLabel {
      font-size: 20px;
      line-height: 24px;
      width: 200px;
      margin-bottom: 18px;
    }

    /deep/ .ant-space-item {
      flex: 1;
    }

    .stepInput {
      height: 48px;
      border-radius: 8px;

      /deep/ input {
        height: 48px;
      }
    }
  }
}

.modalDepositCreditCardTips {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin-top: 20px;
}

.modalDepositCreditCardTipsContainer {
  background: #fafafa;
  border-radius: 8px;
  padding: 30px;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #999999;
  margin-top: 10px;
}

.modalDepositGoToBtnContainer {
  width: 100%;
  text-align: center;
  margin-top: 40px;

  .modalDepositGoToBtn {
    width: 400px;
    height: 48px;

    @media (max-width: 750px) {
      width: 100%;
    }
  }
}

.withdrawModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .inputContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 960px) {
      width: 100%;
    }

    .inputLabel {
      font-family: "Poppins-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      mix-blend-mode: normal;
      margin-bottom: 10px;
    }

    /deep/ .ant-space-item {
      flex: 1;
    }

    .stepInput {
      height: 48px;
      border-radius: 8px;

      /deep/ input {
        height: 48px;
      }
    }

    .stepInputAddon {
      .suffixText {
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;

        span {
          margin: 0 6px;
        }
      }
    }
  }
}

.availableContainer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  margin-top: 5px;
  opacity: 0.5;
}

.withdrawSendMailBtn {
  width: 100%;
  height: 48px;
}

.backContainer {
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
}
</style>
